import { useState, useEffect } from "react";
import axios from "axios";
import { Container, Col } from "react-bootstrap";
import React from "react";
import { Box, FooterContainer, Row, Column, FooterLink, Heading } from "./FooterStylesStyleB";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faXTwitter } from "@fortawesome/free-brands-svg-icons"
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const config = require("../constants/constants.js")

const url_customer_image_logo = config.URL_IMAGES_CUSTOMER1+"mpm-logo.png"; 

const FooterComponentStyleA = () => {

    const [ragioneSociale, setRagioneSociale] = useState("");                      
    const [email, setEmail] = useState("");                      
    const [telefonoCallFromSite, setTelefonoCallFromSite] = useState("");     
    const [linkWhatsapp, setLinkWhatsapp] = useState("");     
    const [linkFacebook, setLinkFacebook] = useState("");     
    const [linkInstagram, setLinkInstagram] = useState("");     
    const [linkTwitter, setLinkTwitter] = useState("");     
    const [linkLinkedin, setLinkLinkedin] = useState("");     
    const [urlSiteImageServer, setUrlSiteImageServer] = useState("");                      
    const [urlCustomerImageServer, setUrlCustomerImageServer] = useState("");                      
    
    const getConfigByProgressivoApiRequest = async (progressivoRagSoc) => {
        console.log("[getConfigByProgressivoApiRequest] leggo const con progressivo =",progressivoRagSoc);
        const url = `/api/configs/progressivo/`+progressivoRagSoc;
        const { data } = await axios.get(url);
        return data
    }
         
  useEffect(() => {
    getConfigByProgressivoApiRequest('0001').then((data) => { 
        console.log(`data =`, data);  
        setRagioneSociale(data?.ragioneSociale);
        setEmail(data?.email);
        setTelefonoCallFromSite("tel:"+data?.telefonoCallFromSite)
        setLinkWhatsapp("https://wa.me/"+data?.linkWhatsapp);
        setLinkFacebook(data?.linkFacebook);
        setLinkInstagram(data?.linkInstagram);
        setLinkTwitter(data?.linkTwitter);
        setLinkLinkedin(data?.linkLinkedin);          
        setUrlSiteImageServer(data?.urlSiteImageServer);
        setUrlCustomerImageServer(data?.urlCustomerImageServer);
        console.log(`data?.linkWhatsapp =`, data?.linkWhatsapp);  
        console.log(`data?.linkFacebook =`, data?.linkFacebook);  
        console.log(`data?.linkInstagram =`, data?.linkInstagram);  
        console.log(`data?.linkTwitter =`, data?.linkTwitter);  
        console.log(`ragioneSociale =`, data?.ragioneSociale);  
        console.log(`urlCustomerImageServer =`, data?.urlCustomerImageServer);  
    })
    .catch((er) => {
        console.log(er);
    });  
  }, [ragioneSociale,setEmail,setUrlCustomerImageServer])
  
  return (
    <Box>
   
        <FooterContainer>

            <Row>
        
                <Column>

                    <Heading
                        style={{
                            color: config.COLOR_TEXT_FOOTER_STYLE_A,
                        }}
                        >MyStudio online&trade;
                    </Heading>

                    <FooterLink>
                        <img src={url_customer_image_logo} alt="bug" height={100} />       
                    </FooterLink>

            {/* 
                    <FooterLink
                        style={{
                            color: config.COLOR_TEXT_FOOTER_STYLE_A,
                        }}
                        >
                       Copyright &copy; 2024 MPM project
                    </FooterLink>

                    <FooterLink
                        style={{
                            color: config.COLOR_TEXT_FOOTER_STYLE_A,
                        }}
                    >
                      WEB AGENCY
                    </FooterLink>

                    <FooterLink href="#"
                        style={{
                            color: config.COLOR_TEXT_FOOTER_STYLE_A,
                            fontStyle: "italic"
                        }}
                        >
                        info@mpmproject.it
                    </FooterLink>
            */}

                </Column>

                <Column>
                    <Heading
                        style={{
                            color: config.COLOR_TEXT_FOOTER_STYLE_A,
                        }}
                        >
                        About Us
                    </Heading>

                    <FooterLink href="https://www.myconcept.it/" target="_blank"
                        style={{
                            color: config.COLOR_TEXT_FOOTER_STYLE_A,
                            fontStyle: "italic"
                        }}
                        >    
                        sitiWeb <b>MyConcept</b> 
                    </FooterLink>

                    <FooterLink href="https://www.myplanneronline.it/" target="_blank"
                        style={{
                            color: config.COLOR_TEXT_FOOTER_STYLE_A,
                            fontStyle: "italic"
                        }}
                        >
                        sitiWeb <b>MyPlanner</b> 
                    </FooterLink>

                    <FooterLink href="https://www.myshoponline.it/" target="_blank"
                        style={{
                            color: config.COLOR_TEXT_FOOTER_STYLE_A,
                            fontStyle: "italic"
                        }}
                        >
                        ecommerce <b>MyShop</b> 
                    </FooterLink>

                </Column>               

                <Column>
    
                    <Heading
                        style={{
                            color: config.COLOR_TEXT_FOOTER_STYLE_A,
                        }}
                        >
                        &nbsp;
                    </Heading>
                    
                    <FooterLink href="https://www.myideaonline.it/" target="_blank"
                        style={{
                            color: config.COLOR_TEXT_FOOTER_STYLE_A,
                            fontStyle: "italic"
                        }}
                        >
                        sitiWeb <b>MyIdea</b> 
                    </FooterLink>

                    <FooterLink href="https://www.myplaceonline.it/" target="_blank"
                        style={{
                            color: config.COLOR_TEXT_FOOTER_STYLE_A,
                            fontStyle: "italic"
                        }}
                        >
                        sitiWeb <b>MyPlace</b> 
                    </FooterLink>

                    <FooterLink href="https://www.mpmproject.it" target="_blank"
                        style={{
                            color: config.COLOR_TEXT_FOOTER_STYLE_A,
                            fontStyle: "italic"
                        }}
                        >
                        www.mpmproject.it
                     </FooterLink>

                </Column>

                <Column>
                
                    <Heading
                        style={{
                            color: config.COLOR_TEXT_FOOTER_STYLE_A,
                        }}
                        >
                        Web Social Media
                    </Heading>

                    <row>

                        <FooterLink href={linkFacebook} target="_blank" title="Facebook®">
                            <FontAwesomeIcon icon={faFacebook}  
                                        style={{
                                            marginLeft: "0px",
                                            color: "orange",
                                            height: "40px",
                                        }}
                            >
                            </FontAwesomeIcon>
                        </FooterLink>

                        <FooterLink href={linkInstagram} target="_blank" title="Instagram®">
                            <FontAwesomeIcon icon={faInstagramSquare}  
                                        style={{
                                            marginLeft: "20px",
                                            color: "orange",
                                            height: "40px",
                                        }}
                            >
                            </FontAwesomeIcon>
                        </FooterLink>

                        <FooterLink href={linkWhatsapp} target="_blank" title="Whatsapp®">
                            <FontAwesomeIcon icon={faWhatsapp}  
                                        style={{
                                            marginLeft: "20px",
                                            color: "orange",
                                            height: "40px",
                                        }}
                            >
                            </FontAwesomeIcon>
                        </FooterLink>

                    </row>

                    <FooterLink 
                            style={{
                                color: config.COLOR_TEXT_FOOTER_STYLE_A,
                                fontStyle: "italic",
                                height: "6px"
                            }}
                            >
                            &nbsp;
                    </FooterLink>

                    <FooterLink 
                            style={{
                                color: config.COLOR_TEXT_FOOTER_STYLE_A,
                                fontStyle: "italic"
                            }}
                            >
                            created by MPM PROJECT
                    </FooterLink>

                    <FooterLink href="/admin/categories"
                            style={{
                                color: config.COLOR_TEXT_FOOTER_STYLE_A,
                                fontStyle: "italic"
                            }}
                            >
                            Login
                    </FooterLink>

                </Column>

            </Row>
 
         </FooterContainer>
 
    </Box>
  );
};


export default FooterComponentStyleA;
