import { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import Box from "@mui/material/Box";
import { Link, useNavigate, useHref } from "react-router-dom";

const config = require("../constants/constants.js")

const CategoryCardComponent = ({ category, idx }) => {

  const isMobile = useMediaQuery({ query: '(min-width: 576px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 768px)' });
  const isPC = useMediaQuery({ query: '(min-width: 992px)' });

  const url_elencoservizi = "/product-list/category/"+category.name;
  const url_image_elencoservizi = config.URL_IMAGES_CUSTOMER1+"elenco-servizi-cat.png";  
  const help_image_elencoservizi = "servizi per "+category.name;  

  let size_image_gotoPage=30;
  //if (isMobile===true) size_image_gotoPage=200;
  //if (isTablet===true) size_image_gotoPage=200;
  //if (isPC===true) size_image_gotoPage=30;

  let navigate = useNavigate();
  
  useEffect(() => {
    console.log({isMobile});
    console.log({isTablet});
    console.log({isPC});
  },[isMobile])

/*

                <Col md={3} class="text-end">
                  <a href={url_elencoservizi} title={help_image_elencoservizi}>
                    <Box position="absolute" bottom="15px" right="15px">
                      <img src={url_image_elencoservizi} alt="bug" height={size_image_gotoPage} />       
                    </Box>          
                  </a>
                </Col>

*/

  return (

  <Card body outline color="success" className="mx-2 my-3 px-0 py-0">

    <Box
      sx={{
        backgroundColor: '#ffffff',
        '&:hover': {
          backgroundColor: '#e9e9e9',
          opacity: [0.9, 0.8, 0.7],
        },
      }}
    >

        <a href={url_elencoservizi}>
          <Card.Img 
            crossOrigin="anonymous" 
            variant="top" 
            src={category.images[0] ? category.images[0].path : ''} 
          />
       </a>

        <Card.Body
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 8,
            paddingBottom: 0,
        }}>

          <Card.Title>
            <Box
              fontSize="h6.fontSize"
              component="div" 
              overflow="hidden"            
              whiteSpace="pre-line"
              textOverflow="ellipsis"
              height={50}          
            >
              {category.name}
            </Box>
          </Card.Title>

          <Card.Text>

            <Box
                component="div" 
                overflow="hidden"            
                whiteSpace="pre-line"
                textOverflow="ellipsis"
                height={80}          
              >
              <Row>
                
                <Col md={9} class="text-start">
                  {category.description}
                </Col>

                <Col md={3} class="text-end">

                  <div 
                    onClick={event =>  {
                      event.preventDefault();
                      navigate(url_elencoservizi)
                    }}
                  >
                    <Box position="absolute" bottom="15px" right="15px">
                      <img 
                        src={url_image_elencoservizi} 
                        title={help_image_elencoservizi}
                      />
                   </Box>
                  </div>

                </Col>

              </Row>          

            </Box>

          </Card.Text>

      </Card.Body>

    </Box>

    </Card>   
  );
};

export default CategoryCardComponent;
