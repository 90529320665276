import { Row, Col, Container, Form, Button, Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { InputGroup } from "react-bootstrap";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { red } from "@mui/material/colors";

import axios from "axios";
import { uploadCategoryImagesApiRequest, uploadImagesCloudinaryApiRequest } from "./../utils/utils";

const onHover = {
  cursor: "pointer",
  position: "absolute",
  left: "5px",
  top: "-10px",
  transform: "scale(2.7)",
};

const EditCategoryPageComponent = ({ updateCategoryApiRequest, fetchCategory }) => {

  const [validated, setValidated] = useState(false);
  const [category, setCategory] = useState([]);
  const [updateCategoryResponseState, setUpdateCategoryResponseState] = useState({ message: "", error: "" });

  const [isUploading, setIsUploading] = useState("");
  const [imageUploaded, setImageUploaded] = useState(false);
  const [imageRemoved, setImageRemoved] = useState(false)

  const { id } = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState("");                      
  const [description, setDescription] = useState("");                      
  const [position, setPosition] = useState(0);                      
  const [color, setColor] = useState("");                      
  const [image, setImage] = useState("");                      

  useEffect(() => {
      fetchCategory(id)
      .then(data => {
          setCategory(data);
      })
      .catch((er) => console.log(er.response.data.message ? er.response.data.message : er.response.data));
      //console.log('category = ',category)
  }, [id])

  useEffect(() => {
    fetchCategory(id)
    .then(data => {
      //console.log(`data =`, data);  
      setName(data?.name);
      setDescription(data?.description);
      setPosition(data?.position);
      setColor(data?.color);
      setImage(data?.image);
      //console.log(`name  =`, name);  
      //console.log(`description  =`, description);  
    })
    .catch((er) => console.log(er.response.data.message ? er.response.data.message : er.response.data));         
},[id])


function salvaCategoryModificata (eventKey) {
  eventKey.preventDefault();
  eventKey.stopPropagation();

  //console.log(`[salvaCategoryModificata] name dig =`, name);  
  //console.log(`[salvaCategoryModificata] description dig =`, description);  
  //console.log(`[salvaCategoryModificata] color dig =`, color);  
  //console.log(`[salvaCategoryModificata] image dig =`, image);  

  updateCategoryApiRequest(id, name, description, position, color, image)
  .then(data => {
      if (data.success === "resource updated") {
          navigate("/admin/categories");
      }
    })
  .catch(er => {
      setUpdateCategoryResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
  })

  window.location.assign('/admin/categories')
}

function gotoIndietro (eventKey) {
  eventKey.preventDefault();
  eventKey.stopPropagation();

  window.location.assign('/admin/categories')
}

const imageCategoryDeleteHandler = async (imagePath, categoryId) => {
    //console.log("[imageCategoryDeleteHandler] categoryId =",categoryId)
    //console.log("[imageCategoryDeleteHandler] imagePath =",imagePath)

    let encoded = encodeURIComponent(imagePath)
    if (process.env.NODE_ENV !== "production") {
      await axios.delete(`/api/categories/admin/image/${encoded}/${categoryId}`);
    } else {
      await axios.delete(`/api/categories/admin/image/${encoded}/${categoryId}`);
    }
}

  return (

    <Container fullWidth>

      <Row className="justify-content-center mt-5">

        <Col md={11}>
          <h1>&nbsp;Modifica categoria</h1>

          <Form noValidate validated={validated} >

            <Box
              component="form"
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

              <TextField id="id-name" label="Nome" variant="outlined" value={name} defaultValue={name}
                style={{
                  width: "300px",
                }}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />

              <TextField id="id-description" label="Descrizione" variant="outlined" value={description} defaultValue={description} 
                style={{
                  width: "400px",
                }}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
              />

              <TextField id="id-position" label="Posizione" variant="outlined" value={position} defaultValue={position} type="number" InputProps={{ inputProps: { min: 0 } }}
                style={{
                  width: "100px",
                }}
                onChange={(event) => {
                  setPosition(event.target.value);
                }}
              />

              <TextField id="id-color" label="Colore" variant="outlined" value={color} defaultValue={color} type="color"
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setColor(event.target.value);
                }}
              />
           
            </Box>


            <Form.Group controlId="formFileMultiple" className="mb-3 mt-3">
              <Form.Label>Immagini</Form.Label>
              <Row>
                {category.images &&
                  category.images.map((image, idx) => (
                    <Col key={idx} style={{ position: "relative" }} xs={3}>
                      <Image
                        crossOrigin="anonymous"
                        src={image.path ?? null}
                        fluid
                      />
                      <i style={onHover} onClick={() => imageCategoryDeleteHandler(image.path, id).then(data => setImageRemoved(!imageRemoved))} className="bi bi-x text-danger"></i>
                    </Col>
                  ))}
              </Row>
              <Form.Control  type="file" multiple onChange={e => {
                 setIsUploading("upload files in corso ..."); 
                 if (process.env.NODE_ENV !== "production") {
                     uploadCategoryImagesApiRequest(e.target.files, id)
                     .then(data => {
                         setIsUploading("upload files completato");
                         setImageUploaded(!imageUploaded);
                     })
                      .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                 } else {
                  uploadCategoryImagesApiRequest(e.target.files, id)
                  .then(data => {
                      setIsUploading("upload files completato");
                      setImageUploaded(!imageUploaded);
                  })
                   .catch((er) => setIsUploading(er.response.data.message ? er.response.data.message : er.response.data));
                  }
              }} />
               {isUploading}
            </Form.Group>

          </Form>

        </Col>

      </Row>

      <Row className="justify-content-center mt-5">
        <Col md={1}>
  
        <Button 
              type="button"
              className="btn btn-warning"
              onClick={(e) => {gotoIndietro(e); }}
              style={{
                width: "80px",
              }}
            >
              Indietro
            </Button>             

         </Col>

          <Col md={1}>
            <Button 
              type="button"
              className="btn btn-primary"
              onClick={(e) => {salvaCategoryModificata(e); }}
              style={{
                width: "80px",
              }}
            >
              Salva
            </Button>             

          </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center"></Row>

    </Container>


    );
};

export default EditCategoryPageComponent;
